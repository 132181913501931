<template>
    <ul class="link-list">
        <template v-for="link in links" :key="JSON.stringify(link)" class="link">
            <li class="link" v-if="!linkIsSocial(link)">
                <Anchor :url="getLink(link)" class="link" :target="shouldOpenInNewTab(link) ? '_blank' : '_self'">
                    <Icon :name="getIcon(link)" class="link-icon"></Icon>
                    <Typography v-if="link.label?.length" variant="body-large" class="link-label">
                        {{ link.label }}
                    </Typography>
                </Anchor>
            </li>
        </template>
    </ul>
</template>

<script setup>
const props = defineProps({
    links: {
        type: Array,
        default: () => [],
    },
});

const linkIsExternal = (link) => link?.type === 'external'
const linkIsInternal = (link) => link?.type === 'internal'
const linkIsDownload = (link) => link?.type === 'download'
const linkIsSocial = (link) => link?.type === 'social'

const getLink = (link) => {
    if (linkIsInternal(link)) {
        return link?.reference?.path;
    }

    if (linkIsExternal(link)) {
        return link?.url;
    }

    if (linkIsDownload(link)) {
        return link?.file?.url;
    }

    return '';
}

const getIcon = (link) => {
    if (linkIsDownload(link)) {
        return 'download';
    }

    return 'link';
}
 
const shouldOpenInNewTab = (link) => link.newTab === true;

</script>

<style lang="scss" scoped>
.link-list {
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
}
.link-list:not(:first-of-type) {
    margin-top: 1.7rem;
}

.link {
    display: inline-flex;
    gap: 0.8rem;
}

.link-icon {
    color: color(blue, default, 0.4);
    width: 1.8rem;
    height: 1.8rem;
}

.link-label {
    color: color(blue, default, 0.6);
    flex: 1;
    @include transition(color, controls);

    &:hover {
        color: color(blue, default, 0.4);
    }
    &:active {
        color: color(blue, active);
    }
}
</style>
